import * as Sentry        from '@sentry/browser';
import getConfig          from 'next/config';

const { sentryDSN } = getConfig().publicRuntimeConfig;

if(sentryDSN){
  Sentry.init({ dsn: sentryDSN });
}

export const notifySentry = (err, req, statusCode) => {

  if(!sentryDSN) return;

  Sentry.configureScope((scope) => {
    if (!req) {
      scope.setTag(`ssr`, false);
    } else {
      scope.setTag(`ssr`, true);
      scope.setExtra(`url`, req.url);
      scope.setExtra(`params`, req.params);
      scope.setExtra(`query`, req.query);
      scope.setExtra(`statusCode`, statusCode);
      scope.setExtra(`headers`, req.headers);

      if (req.user) {
        scope.setUser({ id: req.user.id, email: req.user.email });
      }
    }
  });

  Sentry.captureException(err);
};
